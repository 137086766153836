import React from 'react'
import './Header.scss'
import Flask from '../../assets/flask.svg'
import Burger from './Burger'
import Img from 'gatsby-image'
import {graphql, StaticQuery} from 'gatsby'
import Link from './Link'

class Header extends React.Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
      scrolled: false,
      expanded: false,
    }
  }

  componentDidMount() {
    this.scrollCheck()
    window.onscroll = () => this.scrollCheck()
  }

  scrollCheck() {
    if (window.scrollY > 0) {
      this.setState({scrolled: true})
    } else {
      this.setState({scrolled: false})
    }
  }

  toggleNav() {
    this.setState({expanded: !this.state.expanded})
  }

  render() {
    const {title, sub, data} = this.props
    const {background} = data
    const navClasses = (this.state.scrolled ? 'scrolled ':'') + (this.state.expanded ? 'expanded ' : '') + 'content-padding'
    return (
      <header id="top">
        <nav className={navClasses}>
          <div className="logo">
            <Link to="/#top">
              <img className="logo-icon" src={Flask} alt="logo-icon"/>
            </Link>
            <span className="logo-name">scenelab.io</span>
          </div>
          <Burger onClick={() => this.toggleNav()}/>
          <ul className="nav-links">
            <Link to="/#features">
              <li>Features</li>
            </Link>
            <Link to="/#usecases">
              <li>Use Cases</li>
            </Link>
            <Link to="/#pricing">
              <li>Pricing</li>
            </Link>
            <Link to="/blog">
              <li>Blog</li>
            </Link>
            <Link className="button-bg grey" to="https://app.scenelab.io">
                <li>Open App</li>
            </Link>
          </ul>
        </nav>
        <div className="hero-area ">
          <div className="hero-text middle">
            <h1>{title}<span className="blue">.</span></h1>
            <span>{sub}</span>
          </div>
        </div>

        <Img fluid={background.childImageSharp.fluid} className="headerbg" style={{position: 'absolute', objectPosition:'top center'}}/>
      </header>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            title
          }
        }
        background: file(relativePath: { eq: "headerbg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <Header data={data} {...props}/>
    )}
  />
)
