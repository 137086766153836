import React, {useEffect, useState} from 'react'
import Layout from '../components/Layout'
import './imprint-and-policies.scss'
import {decryptMailTo} from '../utils/decrypt'
import Header from '../components/Header'

/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */

export default function ImprintAndPolicies() {
  const [showTopButton, setShowTopButton] = useState(false)

  const scrollTo = (e, selector) => {
        e.preventDefault()
        document.querySelector(selector).scrollIntoView({
            behavior: 'smooth'
        })
    }

  const handleScroll = (e) => {
    const scrollAmount = e.srcElement.body.scrollTop || e.srcElement.documentElement.scrollTop
    if(scrollAmount > 300) {
      setShowTopButton(true)
    } else {
      setShowTopButton(false)
    }
  }


  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const topButton = showTopButton ? <div onClick={e => scrollTo(e, '#top')} className="top-button">Top</div> : ''

  return (
    <Layout title="Imprint & Policies">
      <a id="top" className="top-anchor"/>
      <Header title={'Imprint & Policies'}/>
      <div className="container policies">
        {topButton}
        <div className="subnav">
            <ul>
                <a onClick={e => scrollTo(e, '#imprint')}>
                    <li>Imprint</li>
                </a>
                <a onClick={e => scrollTo(e, '#terms')}>
                    <li>Terms of Service</li>
                </a>
                <a  onClick={e => scrollTo(e, '#license')}>
                    <li>License Agreement</li>
                </a>
                <a onClick={e => scrollTo(e, '#privacy')}>
                    <li>Privacy Policy</li>
                </a>
            </ul>
        </div>

        <a className="anchor" id="imprint"/>
        <h2 className="section-heading">Imprint</h2>
        <section className="content" style={{display: 'block'}}>
          <address>
            <strong>Henke, Simon &amp; Mehlhorn, Nils GbR</strong><br/>
            Kahrstr. 19<br/>
            45128 Essen<br/>
            Germany<br/>
            E-mail: <a href="mailto:obfuscated" onClick={e => decryptMailTo(e)}>contact(at)scenelab.io</a><br/>
            Website: scenelab.io<br/>
            USt-IdNr.: DE318819078
          </address>
          <p>Authorized representatives and managing directors: Simon Henke, Nils Mehlhorn</p>
          <p>The European Commission provides a platform for extrajudicial online dispute resolution at
            <a href="https://ec.europa.eu/consumers/odr"> https://ec.europa.eu/consumers/odr</a>. We are neither
            obligated
            nor willing to participate in a dispute settlement procedure.</p>
        </section>

        <a className="anchor" id="terms"/>
        <h2 className="section-heading">Terms of Service</h2>
        <section className="content">
          <h3>Scope and Supplier</h3>
          <p>
            The supplier (hereinafter referred to as SceneLab) is identified through the company details specified in
            our <a href="#imprint">imprint</a>. The laid down terms apply to all services provided through our website and
            corresponding interactions with our company and/or employees.
          </p>
          <p>By using SceneLab's services you agree to all of the terms and conditions listed below as well as provide
            acknowledgment for our <a href="#privacy">privacy policy</a>. If you do not understand any of the laid down
            terms, please contact us before using SceneLab's services.</p>
          <h3>Eligibility</h3>
          <p>
            By using our services you verify that you are above the age of 18 years. Furthermore you attest that any
            personal information provided is correct, complete and up-to-date.
          </p>
          <p>Your account and any corresponding information (including access to delivery objects) is strictly personal.
            You are therefore not permitted to sell or sublet your account.</p>
          <h3>Delivery Objects, Prices and Revocation</h3>
          <p>
            The specifications as well as licensing and rights of use for delivery objects are laid down in our
            <a href="#license"> license agreement</a>. These come into effect upon conclusion of subscription
            through our website.
            Prices, receipts and bills are issued using U.S. Dollar. All prices include sales tax where applicable.
            Prices may be subject to alteration. Digital goods are excluded from exchange, therefore no refunds can be
            provided.
          </p>
          <h3>Payment Methods</h3>
          <p>
            Payments on our platform are processed through the certified payment provider Stripe (see
            <a href="https://stripe.com/legal">https://stripe.com/legal</a> for their service agreements). Currently
            only
            payment through credit cards is supported. The payment details provided during checkout are neither directly
            collected on our site, nor is it processed by our servers. The masks for collecting such data embed remotely
            hosted components provided by Stripe. SceneLab therefore does not gain direct access to confidential data
            related
            to payments (also see our <a href="#privacy-stripe">privacy policy for Stripe</a>). Any internet
            communications
            with SceneLab - including the ones for payments - use encryption methods to transfer data securely.
          </p>
          <h3>Subscription</h3>
          <p>
            Use of our services is possible on a subscription basis ("SceneLab Pro"). The billing cycle amounts to one
            month.
            Charges are proceeded in advance. Subscriptions will renew automatically under the same conditions at the
            end of
            each billing cycle. This renewal process may be canceled at any time during the billing cycle. In this case
            your
            subscription won't be renewed at the end of the current cycle. However, access to our services will remain
            until
            the end of the current cycle.
          </p>
          <p>
            In order to conclude a subscription, a valid payment method is required. By concluding a subscription,
            you authorize SceneLab to charge all fees arising from the subscription automatically
            to the provided payment method. Should automatic billing fail for any reason, SceneLab may issue an
            electronic
            invoice and a corresponding payment deadline. Missing the deadline may result in suspension of the
            subscription-based use.
          </p>
          <h3>Disclaimer</h3>
          <p>
            All the information on this website is published in good faith and for general information purpose
            only. SceneLab does not make any warranties about the completeness, reliability and accuracy of this
            information.
            Any action you take upon the information you find on this website, is strictly at your own risk.
            SceneLab will not be liable for any losses and/or damages in connection with the use of our website.

            From our website, you can visit other websites by following hyperlinks to such external sites. While we
            strive
            to provide only quality links to useful and ethical websites, we have no control over the content and nature
            of
            these sites. These links to other websites do not imply a recommendation for all the content found on these
            sites. Site owners and content may change without notice and may occur before we have the opportunity to
            remove
            a link which may have gone 'bad'.

            Please be also aware that when you leave our website, other sites may have different privacy policies and
            terms
            which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their
            "Terms of Service" before engaging in any business or uploading any information.
          </p>
          <h3>Final Clauses</h3>
          <p>
            In the event that any of the laid down terms shall be or become invalid, all other parts of this agreement
            shall
            remain valid. This agreement does not affect your statutory rights. SceneLab reserves the right to modify or
            replace any of the laid down terms at any time.
          </p>

        </section>


        <a className="anchor" id="license"/>
        <h2 className="section-heading">License Agreement</h2>
        <section className="content">
          <p> The following is a legal agreement that defines the terms under which digital assets provided by SceneLab
            can be
            used. This agreement applies to all assets accessed or exported through SceneLab and its services.
            <br/>
            It thereby includes, but is not limited to
          </p>
          <ul>
            <li>thumbnails, item and background images (hereafter referred to as "resource images")</li>
            <li>image compositions created in the editor (hereafter referred to as "scene images" )</li>
          </ul>
          <p>
            It does explicitly exclude branding and advertising material (e.g. logos, icons), as well as any other
            assets deemed inapplicable by common sense.
          </p>
          <p>
            By subscribing to SceneLab's services and subsequently exporting any assets through SceneLab, you agree to
            be
            bound
            to the terms of this license agreement. Licensed usage is only legally granted for assets exported during an
            active subscription cycle.
          </p>
          <p>
            SceneLab reserves the right to update this license agreement at any time and you agree to be bound to any
            changes made to it's terms.
          </p>
          <p>
            You are granted a non-exclusive, perpetual, worldwide, non-transferable and non-sublicensable license to use
            any legally acquired assets according to the permitted areas of application. However, all copyrights and
            intellectual property rights remain with SceneLab.
          </p>
          <h3>Permitted Usage</h3>
          <p>
            You may use SceneLab assets …
          </p>
          <ul>
            <li>in digital publications (online or otherwise), for example in/on web pages, social media posts, ebooks
              or
              videos
            </li>
            <li>for marketing and advertising purposes</li>
            <li>for commissioned work (delivered to definite clients/customers)</li>
            <li>to create hard copies (e.g. prints, posters) for personal and promotional use, while not reselling,
              licensing or
              distributing them in other ways
            </li>
            <li>in merchandise designs, limited to a quantity of 100,000 (one hundred thousand) copies</li>
            <li>in print media such as books, invitations, brochures, magazines, postcards, product packaging, flyers or
              similar
            </li>
            <li>for personal use (not violating the prohibited usage as stated below)</li>
          </ul>
          <h3>Prohibited Usage</h3>

          <p>
            You <strong>must not</strong> sub-license, resell, rent, lend, assign, gift or otherwise distribute SceneLab
            images or the rights
            granted under this license agreement. This includes for example selling any images on stock photo
            marketplaces
            or
            sharing resource images for free.
          </p>
          <p>
            You <strong>must not</strong> use SceneLab assets
          </p>
          <ul>
            <li>in other ways than listed under “Permitted Usage”</li>
            <li>as a trademark, design-mark, logo, brand name or as a part of those</li>
            <li>in a software, web page or other environment in a way that makes the resource images accessible to the
              public
            </li>
            <li>in any illegal, immoral, infringing or defamatory context</li>
            <li>in application areas that are directly competing with SceneLab and its services</li>
          </ul>
          <p>
            Violations to the terms stated in this license agreement result in an instant expiration of the granted
            license
            and will entail legal action. In such a case you are obliged to destroy any physical or digital copies of
            the
            contents acquired under this license. SceneLab also reserves the right to close any account associated with
            violations to the license agreement.
          </p>
        </section>

        <a className="anchor" id="privacy"/>
        <h2 className="section-heading">Privacy Policy</h2>
        <section className="content">
          <p>We are very delighted that you have shown interest in our enterprise. Data protection is of a particularly
            high
            priority for the management of SceneLab. The use of the Internet pages of SceneLab is possible without any
            indication of personal data; however, if a data subject wants to use special enterprise services via our
            website, processing of personal data could become necessary. If the processing of personal data is necessary
            and
            there is no statutory basis for such processing, we generally obtain consent from the data subject.</p>
          <p>The processing of personal data, such as the name, address or e-mail address of a data subject shall always
            be in
            line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data
            protection regulations applicable to SceneLab. By means of this data protection declaration, our enterprise
            would like to inform the general public of the nature, scope, and purpose of the personal data we collect,
            use
            and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the
            rights to which they are entitled.</p>
          <h3>Definitions</h3>
          <p>The data protection declaration of SceneLab is based on the terms used by the European legislator for the
            adoption of the General Data Protection Regulation (GDPR). Our data protection declaration should be legible
            and
            understandable for the general public, as well as our customers and business partners. To ensure this, we
            would
            like to first explain the terminology used.</p>
          <p>In this data protection declaration, we use, inter alia, the following terms:</p>
          <p><strong>Personal data</strong><br/>
            Personal data means any information relating to an identified or identifiable natural person (“data
            subject”).
            An identifiable natural person is one who can be identified, directly or indirectly, in particular by
            reference
            to an identifier such as a name, an identification number, location data, an online identifier or to one or
            more
            factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of
            that
            natural person.</p>
          <p><strong>Data subject</strong><br/>
            Data subject is any identified or identifiable natural person, whose personal data is processed by the
            controller responsible for the processing.</p>
          <p><strong>Processing</strong><br/>
            Processing is any operation or set of operations which is performed on personal data or on sets of personal
            data, whether or not by automated means, such as collection, recording, organisation, structuring, storage,
            adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or
            otherwise
            making available, alignment or combination, restriction, erasure or destruction.</p>
          <p><strong>Restriction of processing</strong><br/>
            Restriction of processing is the marking of stored personal data with the aim of limiting their processing
            in
            the future.</p>
          <p><strong>Profiling</strong><br/>
            Profiling means any form of automated processing of personal data consisting of the use of personal data to
            evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects
            concerning that natural person's performance at work, economic situation, health, personal preferences,
            interests, reliability, behaviour, location or movements.</p>
          <p><strong>Pseudonymisation</strong><br/>
            Pseudonymisation is the processing of personal data in such a manner that the personal data can no longer be
            attributed to a specific data subject without the use of additional information, provided that such
            additional
            information is kept separately and is subject to technical and organisational measures to ensure that the
            personal data are not attributed to an identified or identifiable natural person.</p>
          <p><strong>Controller or controller responsible for the processing</strong><br/>
            Controller or controller responsible for the processing is the natural or legal person, public authority,
            agency
            or other body which, alone or jointly with others, determines the purposes and means of the processing of
            personal data; where the purposes and means of such processing are determined by Union or Member State law,
            the
            controller or the specific criteria for its nomination may be provided for by Union or Member State law.</p>
          <p><strong>Processor</strong><br/>
            Processor is a natural or legal person, public authority, agency or other body which processes personal data
            on
            behalf of the controller.</p>
          <p><strong>Recipient</strong><br/>
            Recipient is a natural or legal person, public authority, agency or another body, to which the personal data
            are
            disclosed, whether a third party or not. However, public authorities which may receive personal data in the
            framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as
            recipients; the processing of those data by those public authorities shall be in compliance with the
            applicable
            data protection rules according to the purposes of the processing.</p>
          <p><strong>Third party</strong><br/>
            Third party is a natural or legal person, public authority, agency or body other than the data subject,
            controller, processor and persons who, under the direct authority of the controller or processor, are
            authorised
            to process personal data.</p>
          <p><strong>Consent</strong><br/>
            Consent of the data subject is any freely given, specific, informed and unambiguous indication of the data
            subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to
            the
            processing of personal data relating to him or her.</p>
          <p><strong>Name and Address of the controller</strong><br/>
            Controller for the purposes of the General Data Protection Regulation (GDPR), other data protection laws
            applicable in Member states of the European Union and other provisions related to data protection is
            provided
            in our <a href="#imprint">imprint</a>.
          </p>
          <h3><a className="anchor" id="cookies"/>Cookies</h3>
          <p>SceneLab uses cookies. Cookies are text files that are stored in a computer system via an Internet
            browser.</p>
          <p>Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a
            unique
            identifier of the cookie. It consists of a character string through which Internet pages and servers can be
            assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites
            and
            servers to differentiate the individual browser of the data subject from other Internet browsers that
            contain
            other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.</p>
          <p>Through the use of cookies, SceneLab can provide the users of this website with more user-friendly services
            that
            would not be possible without the cookie setting.</p>
          <p>By means of a cookie, the information and offers on our website can be optimized with the user in mind.
            Cookies
            allow us, as previously mentioned, to recognize our website users. The purpose of this recognition is to
            make it
            easier for users to utilize our website. The website user that uses cookies, e.g. does not have to enter
            access
            data each time the website is accessed, because this is taken over by the website, and the cookie is thus
            stored
            on the user's computer system.</p>
          <p>The data subject may, at any time, prevent the setting of cookies through our website by means of a
            corresponding
            setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore,
            already
            set cookies may be deleted at any time via an Internet browser or other software programs. This is possible
            in
            all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet
            browser
            used, not all functions of our website may be entirely usable.</p>
          <h3>Collection of general data and information</h3>
          <p>SceneLab collects a series of general data and information when a data subject or automated system calls up
            the
            website. This general data and information are stored in the server log files. Collected may be (1) the
            browser
            types and versions used, (2) the operating system used by the accessing system, (3) the website from which
            an
            accessing system reaches our website (so-called referrers), (4) the sub-websites, (5) the date and time of
            access to the Internet site, (6) an Internet protocol address (IP address), (7) the Internet service
            provider of
            the accessing system, and (8) any other similar data and information that may be used in the event of
            attacks on
            our information technology systems.</p>
          <p>When using these general data and information, SceneLab does not draw any conclusions about the data
            subject.
            Rather, this information is needed to (1) deliver the content of our website correctly, (2) optimize the
            content
            of our website as well as its advertisement, (3) ensure the long-term viability of our information
            technology
            systems and website technology, and (4) provide law enforcement authorities with the information necessary
            for
            criminal prosecution in case of a cyber-attack. Therefore, SceneLab analyzes anonymously collected data and
            information statistically, with the aim of increasing the data protection and data security of our
            enterprise,
            and to ensure an optimal level of protection for the personal data we process. The anonymous data of the
            server
            log files are stored separately from all personal data provided by a data subject.</p>
          <h3>Registration on our website</h3>
          <p>The data subject has the possibility to register on the website of the controller with the indication of
            personal
            data. Which personal data are transmitted to the controller is determined by the respective input mask used
            for
            the registration. The personal data entered by the data subject are collected and stored exclusively for
            internal use by the controller, and for his own purposes. The controller may request transfer to one or more
            processors (e.g. a payment provider) that also uses personal data for an internal purpose which is
            attributable
            to the controller.</p>
          <p>By registering on the website of the controller, the IP address—assigned by the Internet service provider
            (ISP)
            and used by the data subject—date, and time of the registration are also stored. The storage of this data
            takes
            place against the background that this is the only way to prevent the misuse of our services, and, if
            necessary,
            to make it possible to investigate committed offenses. Insofar, the storage of this data is necessary to
            secure
            the controller. This data is not passed on to third parties unless there is a statutory obligation to pass
            on
            the data, or if the transfer serves the aim of criminal prosecution.</p>
          <p>The registration of the data subject, with the voluntary indication of personal data, is intended to enable
            the
            controller to offer the data subject contents or services that may only be offered to registered users due
            to
            the nature of the matter in question. Registered persons are free to change the personal data specified
            during
            the registration at any time, or to have them completely deleted from the data stock of the controller.</p>
          <p>The data controller shall, at any time, provide information upon request to each data subject as to what
            personal
            data are stored about the data subject. In addition, the data controller shall correct or erase personal
            data at
            the request or indication of the data subject, insofar as there are no statutory storage obligations. The
            entirety of the controller’s employees are available to the data subject in this respect as contact
            persons.</p>
          <h3>Subscription to our newsletters</h3>
          <p>On the website of SceneLab, users are given the opportunity to subscribe to our newsletter. The input mask
            used
            for this purpose determines what personal data are transmitted, as well as when the newsletter is ordered
            from
            the controller.</p>
          <p>SceneLab informs its customers and business partners regularly by means of a newsletter about updates to
            our
            services or offers. The newsletter may only be received by the data subject if (1) the data subject has a
            valid
            e-mail address and (2) the data subject registers for the newsletter shipping. A confirmation e-mail will be
            sent to the e-mail address registered by a data subject for the first time for newsletter shipping, for
            legal
            reasons, in the double opt-in procedure. This confirmation e-mail is used to prove whether the owner of the
            e-mail address as the data subject is authorized to receive the newsletter.</p>
          <p>Scenelab's newsletter services are provided through the e-mail marketing platform MailChimp. The operating
            company of MailChimp is The Rocket Science Group LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA
            30308,
            United States. The privacy provisions for contacts of their newsletter services is further explained under
            the
            following link <a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a>.</p>
          <p>During the registration for the newsletter, we also store the IP address of the computer system assigned by
            the
            Internet service provider (ISP) and used by the data subject at the time of the registration, as well as the
            date and time of the registration. The collection of this data is necessary in order to understand the
            (possible) misuse of the e-mail address of a data subject at a later date, and it therefore serves the aim
            of
            the legal protection of the controller.</p>
          <p>The personal data collected as part of a registration for the newsletter will only be used to send our
            newsletter. In addition, subscribers to the newsletter may be informed by e-mail, as long as this is
            necessary
            for the operation of the newsletter service or a registration in question, as this could be the case in the
            event of modifications to the newsletter offer, or in the event of a change in technical circumstances.
            There
            will be no transfer of personal data collected by the newsletter service to third parties. The subscription
            to
            our newsletter may be terminated by the data subject at any time. The consent to the storage of personal
            data,
            which the data subject has given for shipping the newsletter, may be revoked at any time. For the purpose of
            revocation of consent, a corresponding link is found in each newsletter. It is also possible to unsubscribe
            from
            the newsletter at any time directly on the website of the controller, or to communicate this to the
            controller
            in a different way.</p>
          <h3>Newsletter-Tracking</h3>
          <p>The newsletter of SceneLab contains so-called tracking pixels. A tracking pixel is a miniature graphic
            embedded
            in such e-mails, which are sent in HTML format to enable log file recording and analysis. This allows a
            statistical analysis of the success or failure of online marketing campaigns. Based on the embedded tracking
            pixel, SceneLab may see if and when an e-mail was opened by a data subject, and which links in the e-mail
            were
            called up by data subjects.</p>
          <p>Such personal data collected in the tracking pixels contained in the newsletters are stored and analyzed by
            the
            controller in order to optimize the shipping of the newsletter, as well as to adapt the content of future
            newsletters even better to the interests of the data subject. These personal data is processed by the
            further
            mentioned MailChimp. Data subjects are at any time entitled to revoke the respective separate declaration of
            consent issued by means of the double-opt-in procedure. After a revocation, these personal data will be
            deleted
            by the controller. SceneLab automatically regards a withdrawal from the receipt of the newsletter as a
            revocation.</p>
          <h3>Routine erasure and blocking of personal data</h3>
          <p>The data controller shall process and store the personal data of the data subject only for the period
            necessary
            to achieve the purpose of storage, or as far as this is granted by the European legislator or other
            legislators
            in laws or regulations to which the controller is subject to.</p>
          <p>If the storage purpose is not applicable, or if a storage period prescribed by the European legislator or
            another
            competent legislator expires, the personal data are routinely blocked or erased in accordance with legal
            requirements.</p>
          <h3>Rights of the data subject</h3>
          <p><strong>Right of confirmation</strong><br/>
            Each data subject shall have the right granted by the European legislator to obtain from the controller the
            confirmation as to whether or not personal data concerning him or her are being processed. If a data subject
            wishes to avail himself of this right of confirmation, he or she may, at any time, contact any employee of
            the
            controller.</p>
          <p><strong>Right of access</strong><br/>
            Each data subject shall have the right granted by the European legislator to obtain from the controller free
            information about his or her personal data stored at any time and a copy of this information. Furthermore,
            the
            European directives and regulations grant the data subject access to the following information:</p>
          <p>the purposes of the processing;<br/>
            the categories of personal data concerned;<br/>
            the recipients or categories of recipients to whom the personal data have been or will be disclosed, in
            particular recipients in third countries or international organisations;<br/>
            where possible, the envisaged period for which the personal data will be stored, or, if not possible, the
            criteria used to determine that period;<br/>
            the existence of the right to request from the controller rectification or erasure of personal data, or
            restriction of processing of personal data concerning the data subject, or to object to such
            processing;<br/>
            the existence of the right to lodge a complaint with a supervisory authority;<br/>
            where the personal data are not collected from the data subject, any available information as to their
            source;<br/>
            the existence of automated decision-making, including profiling, referred to in Article 22(1) and (4) of the
            GDPR and, at least in those cases, meaningful information about the logic involved, as well as the
            significance
            and envisaged consequences of such processing for the data subject.
            Furthermore, the data subject shall have a right to obtain information as to whether personal data are
            transferred to a third country or to an international organisation. Where this is the case, the data subject
            shall have the right to be informed of the appropriate safeguards relating to the transfer.</p>
          <p>If a data subject wishes to avail himself of this right of access, he or she may, at any time, contact any
            employee of the controller.</p>
          <p><strong>Right to rectification</strong><br/>
            Each data subject shall have the right granted by the European legislator to obtain from the controller
            without
            undue delay the rectification of inaccurate personal data concerning him or her. Taking into account the
            purposes of the processing, the data subject shall have the right to have incomplete personal data
            completed,
            including by means of providing a supplementary statement.</p>
          <p>If a data subject wishes to exercise this right to rectification, he or she may, at any time, contact any
            employee of the controller.</p>
          <p><strong>Right to erasure (Right to be forgotten)</strong><br/>
            Each data subject shall have the right granted by the European legislator to obtain from the controller the
            erasure of personal data concerning him or her without undue delay, and the controller shall have the
            obligation
            to erase personal data without undue delay where one of the following grounds applies, as long as the
            processing
            is not necessary:</p>
          <p>The personal data are no longer necessary in relation to the purposes for which they were collected or
            otherwise
            processed.
            The data subject withdraws consent to which the processing is based according to point (a) of Article 6(1)
            of
            the GDPR, or point (a) of Article 9(2) of the GDPR, and where there is no other legal ground for the
            processing.
            The data subject objects to the processing pursuant to Article 21(1) of the GDPR and there are no overriding
            legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article
            21(2)
            of the GDPR.
            The personal data have been unlawfully processed.
            The personal data must be erased for compliance with a legal obligation in Union or Member State law to
            which
            the controller is subject.
            The personal data have been collected in relation to the offer of information society services referred to
            in
            Article 8(1) of the GDPR.
            If one of the aforementioned reasons applies, and a data subject wishes to request the erasure of personal
            data
            stored by SceneLab, he or she may, at any time, contact any employee of the controller. An employee of
            Henke,
            Simon &amp; Mehlhorn, Nils GbR shall promptly ensure that the erasure request is complied with
            immediately.</p>
          <p>Where the controller has made personal data public and is obliged pursuant to Article 17(1) to erase the
            personal
            data, the controller, taking account of available technology and the cost of implementation, shall take
            reasonable steps, including technical measures, to inform other controllers processing the personal data
            that
            the data subject has requested erasure by such controllers of any links to, or copy or replication of, those
            personal data, as far as processing is not required. An employees of SceneLab will arrange the necessary
            measures in individual cases.</p>
          <p><strong>Right of restriction of processing</strong><br/>
            Each data subject shall have the right granted by the European legislator to obtain from the controller
            restriction of processing where one of the following applies:</p>
          <p>The accuracy of the personal data is contested by the data subject, for a period enabling the controller to
            verify the accuracy of the personal data.
            The processing is unlawful and the data subject opposes the erasure of the personal data and requests
            instead
            the restriction of their use instead.
            The controller no longer needs the personal data for the purposes of the processing, but they are required
            by
            the data subject for the establishment, exercise or defence of legal claims.
            The data subject has objected to processing pursuant to Article 21(1) of the GDPR pending the verification
            whether the legitimate grounds of the controller override those of the data subject.
            If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of the
            processing of personal data stored by SceneLab, he or she may at any time contact any employee of the
            controller. The employee of SceneLab will arrange the restriction of the processing.</p>
          <p><strong>Right to data portability</strong><br/>
            Each data subject shall have the right granted by the European legislator, to receive the personal data
            concerning him or her, which was provided to a controller, in a structured, commonly used and
            machine-readable
            format. He or she shall have the right to transmit those data to another controller without hindrance from
            the
            controller to which the personal data have been provided, as long as the processing is based on consent
            pursuant
            to point (a) of Article 6(1) of the GDPR or point (a) of Article 9(2) of the GDPR, or on a contract pursuant
            to
            point (b) of Article 6(1) of the GDPR, and the processing is carried out by automated means, as long as the
            processing is not necessary for the performance of a task carried out in the public interest or in the
            exercise
            of official authority vested in the controller.</p>
          <p>Furthermore, in exercising his or her right to data portability pursuant to Article 20(1) of the GDPR, the
            data
            subject shall have the right to have personal data transmitted directly from one controller to another,
            where
            technically feasible and when doing so does not adversely affect the rights and freedoms of others.</p>
          <p>In order to assert the right to data portability, the data subject may at any time contact any employee of
            SceneLab.</p>
          <p><strong>Right to object</strong><br/>
            Each data subject shall have the right granted by the European legislator to object, on grounds relating to
            his
            or her particular situation, at any time, to processing of personal data concerning him or her, which is
            based
            on point (e) or (f) of Article 6(1) of the GDPR. This also applies to profiling based on these provisions.
          </p>
          <p>SceneLab shall no longer process the personal data in the event of the objection, unless we can demonstrate
            compelling legitimate grounds for the processing which override the interests, rights and freedoms of the
            data
            subject, or for the establishment, exercise or defence of legal claims.</p>
          <p>If SceneLab processes personal data for direct marketing purposes, the data subject shall have the right to
            object at any time to processing of personal data concerning him or her for such marketing. This applies to
            profiling to the extent that it is related to such direct marketing. If the data subject objects to SceneLab
            to
            the processing for direct marketing purposes, SceneLab will no longer process the personal data for these
            purposes.</p>
          <p>In addition, the data subject has the right, on grounds relating to his or her particular situation, to
            object to
            processing of personal data concerning him or her by SceneLab for scientific or historical research
            purposes, or
            for statistical purposes pursuant to Article 89(1) of the GDPR, unless the processing is necessary for the
            performance of a task carried out for reasons of public interest.</p>
          <p>In order to exercise the right to object, the data subject may contact any employee of SceneLab. In
            addition,
            the
            data subject is free in the context of the use of information society services, and notwithstanding
            Directive
            2002/58/EC, to use his or her right to object by automated means using technical specifications.</p>
          <p><strong>Automated individual decision-making, including profiling</strong><br/>
            Each data subject shall have the right granted by the European legislator not to be subject to a decision
            based
            solely on automated processing, including profiling, which produces legal effects concerning him or her, or
            similarly significantly affects him or her, as long as the decision (1) is not is necessary for entering
            into,
            or the performance of, a contract between the data subject and a data controller, or (2) is not authorised
            by
            Union or Member State law to which the controller is subject and which also lays down suitable measures to
            safeguard the data subject's rights and freedoms and legitimate interests, or (3) is not based on the data
            subject's explicit consent.</p>
          <p>If the decision (1) is necessary for entering into, or the performance of, a contract between the data
            subject
            and a data controller, or (2) it is based on the data subject's explicit consent, SceneLab shall implement
            suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, at least the
            right to obtain human intervention on the part of the controller, to express his or her point of view and
            contest the decision.</p>
          <p>If the data subject wishes to exercise the rights concerning automated individual decision-making, he or
            she
            may,
            at any time, contact any employee of SceneLab.</p>
          <p><strong>Right to withdraw data protection consent</strong><br/>
            Each data subject shall have the right granted by the European legislator to withdraw his or her consent to
            processing of his or her personal data at any time.</p>
          <p>If the data subject wishes to exercise the right to withdraw the consent, he or she may, at any time,
            contact
            any
            employee of SceneLab.</p>
          <h3>Data protection provisions about the application and use of Google Analytics (with anonymization
            function)</h3>
          <p>On this website, the controller has integrated the component of Google Analytics (with the anonymizer
            function).
            Google Analytics is a web analytics service. Web analytics is the collection, gathering, and analysis of
            data
            about the behavior of visitors to websites. A web analysis service collects, inter alia, data about the
            website
            from which a person has come (the so-called referrer), which sub-pages were visited, or how often and for
            what
            duration a sub-page was viewed. Web analytics are mainly used for the optimization of a website and in order
            to
            carry out a cost-benefit analysis of Internet advertising.</p>
          <p>The operator of the Google Analytics component is Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA
            94043-1351, United States.</p>
          <p>For the web analytics through Google Analytics the controller uses the application &quot;_gat.
            _anonymizeIp&quot;.
            By means of this application the IP address of the Internet connection of the data subject is abridged by
            Google
            and anonymised when accessing our websites from a Member State of the European Union or another Contracting
            State to the Agreement on the European Economic Area.</p>
          <p>The purpose of the Google Analytics component is to analyze the traffic on our website. Google uses the
            collected
            data and information, inter alia, to evaluate the use of our website and to provide online reports, which
            show
            the activities on our websites, and to provide other services concerning the use of our Internet site for
            us.</p>
          <p>Google Analytics places a cookie on the information technology system of the data subject. The definition
            of
            cookies is explained above. With the setting of the cookie, Google is enabled to analyze the use of our
            website.
            With each call-up to one of the individual pages of this Internet site, which is operated by the controller
            and
            into which a Google Analytics component was integrated, the Internet browser on the information technology
            system of the data subject will automatically submit data through the Google Analytics component for the
            purpose
            of online advertising and the settlement of commissions to Google. During the course of this technical
            procedure, the enterprise Google gains knowledge of personal information, such as the IP address of the data
            subject, which serves Google, inter alia, to understand the origin of visitors and clicks, and subsequently
            create commission settlements.</p>
          <p>The cookie is used to store personal information, such as the access time, the location from which the
            access
            was
            made, and the frequency of visits of our website by the data subject. With each visit to our Internet site,
            such
            personal data, including the IP address of the Internet access used by the data subject, will be transmitted
            to
            Google in the United States of America. These personal data are stored by Google in the United States of
            America. Google may pass these personal data collected through the technical procedure to third parties.</p>
          <p>The data subject may, as stated above, prevent the setting of cookies through our website at any time by
            means of
            a corresponding adjustment of the web browser used and thus permanently deny the setting of cookies. Such an
            adjustment to the Internet browser used would also prevent Google Analytics from setting a cookie on the
            information technology system of the data subject. In addition, cookies already in use by Google Analytics
            may
            be deleted at any time via a web browser or other software programs.</p>
          <p>In addition, the data subject has the possibility of objecting to a collection of data that are generated
            by
            Google Analytics, which is related to the use of this website, as well as the processing of this data by
            Google
            and the chance to preclude any such. For this purpose, the data subject must download a browser add-on under
            the
            link <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> and
            install it. This browser add-on tells Google Analytics through a JavaScript, that any data and information
            about the visits of Internet pages may not be transmitted to Google Analytics.
            The installation of the browser add-ons is considered an objection by Google. If the information
            technology system of the data subject is later deleted, formatted, or newly installed, then the data subject
            must reinstall the browser add-ons to disable Google Analytics. If the browser add-on was uninstalled by the
            data subject or any other person who is attributable to their sphere of competence, or is disabled, it is
            possible to execute the reinstallation or reactivation of the browser add-ons.</p>
          <p>Further information and the applicable data protection provisions of Google may be retrieved under
            <a
              href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>
            and under <a
              href="http://www.google.com/analytics/terms/us.html">http://www.google.com/analytics/terms/us.html</a>.
            Google Analytics is further explained under the following link
            <a href="https://www.google.com/analytics/">https://www.google.com/analytics/</a>.
          </p>
          <h3><a className="anchor" id="privacy-stripe"/>Payment Method: Data protection provisions about the use of Stripe as a payment
            processor</h3>
          <p>On this website, the controller has integrated components of Stripe. Stripe is an online payment service
            provider. Payments are processed via so-called payment sources, which represent e.g. credit cards. The data
            provided in form of such a payment source (e.g. credit card number) is neither directly collected on our
            site,
            nor is it processed by our servers. The masks for collecting such data embed remotely hosted components
            provided
            by Stripe. SceneLab therefore does not gain direct access to confidential data related to payments.</p>
          <p>The operating company of Stripe is Stripe Payments Europe, Limited, The One Building, 1 Grand Canal Street
            Lower,
            Dublin 2, Co. Dublin, Ireland.</p>
          <p>If the data subject chooses to save a payment source for later reuse, the actual payment data is not stored
            by
            SceneLab but by Stripe. SceneLab may reference this data in order to process payments based on it.</p>
          <p>The personal data transmitted to Stripe is usually first name, last name, address, email address, IP
            address
            or
            other data necessary for payment processing. The processing of the purchase contract also requires such
            personal data, which are in connection with the respective order.</p>
          <p>The transmission of the data is aimed at payment processing and fraud prevention. The controller will
            transfer
            personal data to Stripe, in particular, if a legitimate interest in the transmission is given. The personal
            data
            exchanged between Stripe and the controller for the processing of the data will be transmitted by Stripe to
            economic credit agencies. This transmission is intended for identity and creditworthiness checks.</p>
          <p>Stripe will, if necessary, pass on personal data to affiliates and service providers or subcontractors to
            the
            extent that this is necessary to fulfill contractual obligations or for data to be processed in the
            order.</p>
          <p>The data subject has the possibility to revoke consent for the handling of personal data at any time from
            Stripe.
            A revocation shall not have any effect on personal data which must be processed, used or transmitted in
            accordance with (contractual) payment processing.</p>
          <p>The applicable data protection provisions of Stripe may be retrieved under
            <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
          </p>
          <h3>Legal basis for the processing</h3>
          <p>Art. 6(1) lit. a GDPR serves as the legal basis for processing operations for which we obtain consent for a
            specific processing purpose. If the processing of personal data is necessary for the performance of a
            contract
            to which the data subject is party, as is the case, for example, when processing operations are necessary
            for
            the supply of goods or to provide any other service, the processing is based on Article 6(1) lit. b GDPR.
            The
            same applies to such processing operations which are necessary for carrying out pre-contractual measures,
            for
            example in the case of inquiries concerning our products or services. Is our company subject to a legal
            obligation by which processing of personal data is required, such as for the fulfillment of tax obligations,
            the
            processing is based on Art. 6(1) lit. c GDPR. In rare cases, the processing of personal data may be
            necessary to
            protect the vital interests of the data subject or of another natural person. This would be the case, for
            example, if a visitor were injured in our company and his name, age, health insurance data or other vital
            information would have to be passed on to a doctor, hospital or other third party. Then the processing would
            be
            based on Art. 6(1) lit. d GDPR. Finally, processing operations could be based on Article 6(1) lit. f GDPR.
            This
            legal basis is used for processing operations which are not covered by any of the abovementioned legal
            grounds,
            if processing is necessary for the purposes of the legitimate interests pursued by our company or by a third
            party, except where such interests are overridden by the interests or fundamental rights and freedoms of the
            data subject which require protection of personal data. Such processing operations are particularly
            permissible
            because they have been specifically mentioned by the European legislator. He considered that a legitimate
            interest could be assumed if the data subject is a client of the controller (Recital 47 Sentence 2
            GDPR).</p>
          <h3>Legitimate interests pursued by the controller or by a third party</h3>
          <p>Where the processing of personal data is based on Article 6(1) lit. f GDPR our legitimate interest is to
            carry
            out our business in favor of the well-being of all our employees and the shareholders.</p>
          <h3>Period for which the personal data will be stored</h3>
          <p>The criteria used to determine the period of storage of personal data is the respective statutory retention
            period. After expiration of that period, the corresponding data is routinely deleted, as long as it is no
            longer
            necessary for the fulfillment of the contract or the initiation of a contract.</p>
          <h3>Provision of personal data as statutory or contractual requirement; Requirement necessary to enter into a
            contract; Obligation of the data subject to provide the personal data; possible consequences of failure to
            provide such data</h3>
          <p>We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also
            result from contractual provisions (e.g. information on the contractual partner). Sometimes it may be
            necessary
            to conclude a contract that the data subject provides us with personal data, which must subsequently be
            processed by us. The data subject is, for example, obliged to provide us with personal data when our company
            signs a contract with him or her. The non-provision of the personal data would have the consequence that the
            contract with the data subject could not be concluded. Before personal data is provided by the data subject,
            the
            data subject must contact any employee. The employee clarifies to the data subject whether the provision of
            the
            personal data is required by law or contract or is necessary for the conclusion of the contract, whether
            there
            is an obligation to provide the personal data and the consequences of non-provision of the personal
            data.</p>
          <h3>Existence of automated decision-making</h3>
          <p>As a responsible company, we do not use automatic decision-making or profiling.</p>
        </section>
      </div>
    </Layout>
  )
}
